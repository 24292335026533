/** @jsx jsx */
import { jsx, Button, Text } from "theme-ui";
import { graphql } from "gatsby";
import { Fragment } from "react";

import { PageHeading, PortableText, ProtectedImage, Layout } from "components";

function ContactPage({
  data: {
    sanityContactPage: { content, map_image },
  },
}) {
  return (
    <Layout pageTitle="Contact">
      {({ openContactForm }) => (
        <Fragment>
          <PageHeading heading="Contact" />

          {map_image?.asset?.gatsbyImageData && (
            <ProtectedImage
              loading="eager"
              image={map_image.asset.gatsbyImageData}
              alt="Austin / Desmond location"
              placeholder="tracedSVG"
              sx={{
                ml: [null, "xl"],
                mb: [null, "m"],
                float: [null, "right"],
                width: [null, 400, 600],
              }}
            />
          )}

          {content && <PortableText content={content} />}

          <Text as="p" mb="l">
            <Button
              type="button"
              onClick={openContactForm}
              variant="links.primary"
            >
              Join Our Mailing List
            </Button>
          </Text>
        </Fragment>
      )}
    </Layout>
  );
}

export const query = graphql`
  query ContactPage {
    sanityContactPage(_id: { eq: "contact_page" }) {
      map_image {
        asset {
          gatsbyImageData(width: 686)
        }
      }
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default ContactPage;
